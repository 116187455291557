<template>
  <div>
    <div class="bread-crumb">
      <router-link
        :to="{ name: 'Credentials' }"
        class="title-link"
      >
        {{ $t('credentials.apiKeyList') }}
      </router-link>
      <span class="divider">/</span>
      {{ $t('credentials.apiKeyContent') }}
    </div>
    <div class="title-block">
      <h1 class="title-block__title">
        {{ $t('credentials.apiKeyContent') }}
      </h1>
      <button
        class="btn btn-default title-block__btn--delete"
        @click="toggleDeleteModal()"
      >
        {{ $t('credentials.deleteKey') }}
      </button>
    </div>
    <!-- API KEY SECTION -->
    <section class="info-container">
      <h2 class="info-container__title">
        {{ $t('credentials.apiKey') }}
      </h2>
      <spinner
        v-if="isLoading"
        class="spinner"
      />
      <div
        v-else
        class="info-container__content"
      >
        <div class="info-block">
          <h3 class="info-block__title">
            {{ $t('credentials.keyCode') }}:
          </h3>
          <div class="info-block__content">
            {{ keyInfo.key }}
          </div>
          <a
            class="link info-block__link"
            @click="toggleRecreateModal"
          >{{ $t('credentials.recreate') }}
          </a>
        </div>
      </div>
    </section>
    <!-- KEY SETTING SECTION -->
    <section class="info-container">
      <h2 class="info-container__title">
        {{ $t('credentials.keySetting') }}
      </h2>
      <spinner
        v-if="isLoading"
        class="spinner"
      />
      <div
        v-else
        class="info-wrapper"
      >
        <div class="info-block">
          <label class="info-block__title">
            {{ $t('credentials.credentialName') }}:
          </label>
          <input-verify
            class="info-block__input"
            v-validate="'required'"
            :name="'name'"
            :placeholder="$t('editing.pleaseEnterName')"
            v-model="keyInfo.name"
          />
        </div>
        <div class="info-block">
          <label class="info-block__title">
            {{ $t('credentials.description') }}:
          </label>
          <input-verify
            class="info-block__input"
            :name="'description'"
            :placeholder="$t('editing.pleaseEnterDescription')"
            v-model="keyInfo.description"
          />
        </div>
        <div class="info-block">
          <label class="info-block__title">
            {{ $t('credentials.expiredDate') }}:
          </label>
          <el-date-picker
            class="info-block__input"
            v-model="keyInfo.expiredDate"
            :type="'date'"
            :placeholder="$t('credentials.selectDate')"
            :format="'yyyy-MM-dd'"
            :picker-options="datePicker.options"
          />
        </div>
        <div class="info-block">
          <button
            v-if="isEditBtnShow"
            :disabled="isProcessing"
            class="btn btn-default info-block__btn--edit"
            @click="saveEdit"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </section>
    <!-- CREATE INFORMATION SECTION -->
    <section class="info-container">
      <h2 class="info-container__title">
        {{ $t('credentials.createInfo') }}
      </h2>
      <spinner
        v-if="isLoading"
        class="spinner"
      />
      <div
        v-else
        class="info-wrapper"
      >
        <div class="info-block">
          <h3 class="info-block__title">
            {{ $t('editing.createdTime') }}:
          </h3>
          <div class="info-block__content">
            {{ timeToDateTime(keyInfo.createDate) }}
          </div>
        </div>
        <div class="info-block">
          <h3 class="info-block__title">
            {{ $t('editing.creator') }}:
          </h3>
          <div class="info-block__content">
            {{ keyInfo.creatorName }}
          </div>
        </div>
      </div>
    </section>
    <!-- 重新生成金鑰代碼彈跳視窗 -->
    <decide-dialog
      v-if="isRecreateModalShow"
      :title="$t('credentials.recreateAPIKeyWarning')"
      :content="$t('credentials.recreateAPIKeyConfirm')"
      :btn-text="$t('button.confirm')"
      :type="'confirm'"
      @closeDialog="toggleRecreateModal"
      @confirmBtn="recreateKey"
      :is-processing="isProcessing"
    />
    <!-- 刪除金鑰彈跳視窗 -->
    <decide-dialog
      v-if="isDeleteModalShow"
      :title="$t('credentials.deleteAPIKeyWarning')"
      :content="$t('credentials.deleteAPIKeyConfirm')"
      :btn-text="$t('button.confirm')"
      :type="'confirm'"
      :is-processing="isProcessing"
      @closeDialog="toggleDeleteModal"
      @confirmBtn="deleteKey"
    />
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify'
import DecideDialog from '@/components/dialog/DecideDialog'
import { getAPIKey, editAPIKey, recreateAPIKey, deleteAPIKey } from '@/API/Credential'
import { Message } from 'element-ui'
import { mapState } from 'vuex'

export default {
  inject: ['$validator'],
  name: 'EditCredential',
  components: {
    InputVerify,
    DecideDialog
  },
  data () {
    return {
      isProcessing: false,
      isLoading: false,
      isRecreateModalShow: false,
      isDeleteModalShow: false,
      keyInfo: {
        id: '',
        name: '',
        description: '',
        key: '',
        createDate: '',
        expiredDate: '',
        creatorName: ''
      },
      cacheInfo: {
        cacheExpiredDate: '',
        cacheName: '',
        cacheDescription: ''
      },
      datePicker: {
        options: {
          disabledDate: (time) => time.getTime() < new Date().getTime()
        }
      }
    }
  },
  computed: {
    ...mapState('credentials', ['paginationInfo']),
    isEditBtnShow () {
      return !(this.cacheInfo.cacheExpiredDate === this.keyInfo.expiredDate &&
      this.cacheInfo.cacheName === this.keyInfo.name &&
      this.cacheInfo.cacheDescription === this.keyInfo.description)
    }
  },
  created () {
    this.fetchEditingKey()
  },
  methods: {
    toggleRecreateModal () {
      this.isRecreateModalShow = !this.isRecreateModalShow
    },
    toggleDeleteModal () {
      this.isDeleteModalShow = !this.isDeleteModalShow
    },
    fetchEditingKey () {
      this.isLoading = true

      const keyId = this.$route.params.key_id
      getAPIKey(keyId)
        .then((res) => {
          this.keyInfo = res
          this.setCacheInfo()
          this.isLoading = false
        })
        .catch(() => {
        // NoAPI: 將此處關掉
          this.isLoading = false

          Message({
            message: this.$t('credentials.fetchAPIKeyFailed'),
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
        })
    },
    setCacheInfo () {
      this.cacheInfo.cacheDescription = this.keyInfo.description
      this.cacheInfo.cacheName = this.keyInfo.name
      this.cacheInfo.cacheExpiredDate = this.keyInfo.expiredDate
    },
    saveEdit () {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return

          this.isProcessing = true
          const requestBody = {
            description: this.keyInfo.description,
            expiredDate: this.keyInfo.expiredDate,
            name: this.keyInfo.name
          }

          editAPIKey(this.keyInfo.id, requestBody)
            .then(() => {
              this.isProcessing = false
              this.setCacheInfo()
              Message({
                message: this.$t('credentials.editAPIKeySuccess'),
                type: 'success',
                duration: 3 * 1000,
                showClose: true
              })
            })
            .catch(() => {
              this.isProcessing = false
              Message({
                message: this.$t('credentials.editAPIKeyFailed'),
                type: 'error',
                duration: 3 * 1000,
                showClose: true
              })
            })
        })
    },
    recreateKey () {
      this.isProcessing = true
      recreateAPIKey(this.keyInfo.id)
        .then((res) => {
          // 結束process、關閉對話框
          this.isProcessing = false
          this.toggleRecreateModal()
          // 顯示新的keyCode
          this.keyInfo.key = res.key

          Message({
            message: this.$t('credentials.recreateAPIKeySuccess'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {
          // 結束process、關閉對話框
          this.isProcessing = false
          this.toggleRecreateModal()
          Message({
            message: this.$t('credentials.recreateAPIKeyFailed'),
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
        })
    },
    deleteKey () {
      this.isProcessing = true
      deleteAPIKey(this.keyInfo.id)
        .then(() => {
          Message({
            message: this.$t('message.deleteSuccess'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          // 成功刪除後結束procees、關閉視窗
          this.isProcessing = false
          this.toggleDeleteModal()
          // 導回金鑰列表頁面
          this.$router.push({ name: 'Credentials' })
        }
        )
        .catch(() => {
          Message({
            message: this.$t('message.deleteFailed'),
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
          this.isProcessing = false
          this.toggleDeleteModal()
        })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'Credentials') {
      this.$store.commit('credentials/setPaginationInfo', {
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
        itemPerPage: 20
      })
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.info-block {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-items: start;

  &:not(:last-child) {
    padding-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    margin: 0;
  }

  &__input {
    ::v-deep .input-verify-text {
      color: $theme-text-color;
      margin-bottom: 10px;
      width: 180%;
    }

    ::v-deep .input-error {
      bottom: -10px;
    }

    ::v-deep .el-input__inner {
      background-color: transparent;
      border-bottom: 1px solid white;
      border-radius: unset;
      font-size: 16px;
      padding: 0 !important;
    }
  }

  &__btn--edit {
    margin-top: 16px;
  }

  &__link {
    justify-self: end;
  }
}
</style>
